import { useRecoilState } from "recoil";
import { notificationsState } from "../states/notification";
import { MESSAGES } from "../modules/constants";

export const useAddNotification = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  return ({ type, content }) => {
    const newMessage =
      MESSAGES[content?.response?.data?.error] ||
      content?.response?.data?.error ||
      content?.message ||
      MESSAGES[content?.data?.message] ||
      content;
    setNotifications([...notifications, { type, message: newMessage }]);
  };
};
