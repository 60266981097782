import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CourseCreation from "./CourseCreation";
import { useUserInfo } from "../../../../modules/AuthenticationProvider";
import { useAddNotification } from "../../../../modules/notification";
import { createCourse, editCourse } from "../../../../queries/course";
import * as Validator from "../../../../modules/validators";

const CourseCreationDialog = ({ open, handleClose, course, setCourse }) => {
  const { userInfo } = useUserInfo();
  const addNotification = useAddNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Validator.titleSchema(),
    description: Validator.descriptionSchema(),
    thumbnail: Validator.thumbnailSchema(!course),
    category: Validator.categorySchema(),
    difficulty: Validator.difficultySchema(),
    estimation: Validator.estimationSchema(),
    public: Validator.publicSchema(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
      thumbnail: null,
      category: "programming",
      difficulty: "beginner",
      estimation: 0,
      public: false,
    },
  });

  const onSubmit = (data) => {
    if (loading) {
      return false;
    }
    setLoading(true);
    if (course) {
      editCourse(course._id, data, userInfo.accessToken)
        .then((res) => {
          setCourse(res.data.data);
          handleClose();
        })
        .catch((err) => {
          addNotification({ type: "error", content: err });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createCourse(data, userInfo.accessToken)
        .then((res) => {
          addNotification({
            type: "success",
            content: "Course created successfully!",
          });
          navigate(res.data.courseId);
        })
        .catch((err) => {
          addNotification({
            type: "error",
            content: err,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    reset({
      title: course?.title || "",
      description: course?.description || "",
      category: course?.category || "programming",
      difficulty: course?.difficulty || "beginner",
      estimation: course?.estimation || 0,
      public: course?.public || false,
    });
  }, [course]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {course ? "Edit your Course Overview" : "Create your Course"}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CourseCreation
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            course={course}
          />
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={loading}
            sx={{ mt: 4 }}
          >
            {course ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CourseCreationDialog;
