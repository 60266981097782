import * as Yup from "yup";

export const emailSchema = () => {
  return Yup.string()
    .email("invalid_email_shape")
    .max(150, "invalid_email_length")
    .required("required");
};

export const passwordSchema = () => {
  return Yup.string()
    .min(8, "invalid_password_length")
    .max(100, "invalid_password_length")
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "invalid_password_character"
    )
    .required("required");
};

export const nameSchema = () => {
  return Yup.string().max(50, "invalid_name_length").required("required");
};

export const updateSchema = () => {
  return Yup.boolean().required("required");
};

export const titleSchema = () => {
  return Yup.string().max(150, "invalid_title_length").required("required");
};

export const descriptionSchema = () => {
  return Yup.string()
    .max(1000, "invalid_description_length")
    .required("required");
};

export const thumbnailSchema = (required = true) => {
  const validator = Yup.mixed()
    .test(
      "fileSize",
      "invalid_file_size",
      (value) => value?.size <= 5242880 || true // 5MB
    )
    .test(
      "fileFormat",
      "invalid_file_format",
      (value) => value?.type === "image/png" || true
    );
  if (required) {
    return validator.required("required");
  }
  return validator.nullable().notRequired();
};

export const categorySchema = () => {
  return Yup.string().required("required");
};

export const difficultySchema = () => {
  return Yup.string().required("required");
};

export const estimationSchema = () => {
  return Yup.number()
    .test("positive", "invalid_estimation", (value) => value > 0)
    .required("required");
};

export const publicSchema = () => {
  return Yup.boolean();
};

export const lessonsSchema = () => {
  return Yup.array().of(Yup.object()).min(1, "invalid_lesson_length");
};

export const unitsSchema = () => {
  return Yup.array().of(Yup.object()).min(1, "invalid_unit_length");
};
