import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GoogleIcon, FacebookIcon } from "./CustomIcons";
import { signUp } from "../../../queries/auth";
import { useAddNotification } from "../../../modules/notification";
import { useIsLogin } from "../../../modules/AuthenticationProvider";
import * as Validator from "../../../modules/validators";
import { MESSAGES } from "../../../modules/constants";

const validationSchema = Yup.object().shape({
  name: Validator.nameSchema(),
  email: Validator.emailSchema(),
  password: Validator.passwordSchema(),
  update: Validator.updateSchema(),
});

export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const addNotification = useAddNotification();
  const isLogin = useIsLogin();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      update: false,
    },
  });

  const onSubmit = (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    signUp(data)
      .then((res) => {
        addNotification({
          type: "success",
          content: res,
        });
        navigate("/login");
      })
      .catch((err) => {
        addNotification({
          type: "error",
          content: err,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/dashboard/learning");
    }
  }, [navigate, isLogin]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: 12, pb: 12 }}>
      <Paper elevation={5} sx={{ maxWidth: 450, width: "100%", padding: 4 }}>
        <Typography
          component="h1"
          variant="h4"
          textAlign="center"
          sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
        >
          Sign up
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <FormControl>
            <FormLabel htmlFor="name">Full name</FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoComplete="name"
                  name="name"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  placeholder="Type Full Name ..."
                  error={!!errors.name}
                  helperText={MESSAGES[errors.name?.message] || ""}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoComplete="email"
                  name="email"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  placeholder="Type email ..."
                  error={!!errors.email}
                  helperText={MESSAGES[errors.email?.message] || ""}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="password"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.password}
                  helperText={MESSAGES[errors.password?.message] || ""}
                />
              )}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Controller
                name="update"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    color="primary"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="I want to receive updates via email."
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
          >
            Sign up
          </LoadingButton>
          <Typography sx={{ textAlign: "center" }}>
            Already have an account?
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </Button>
          </Typography>
        </Box>
        <Divider>
          <Typography sx={{ color: "text.secondary" }}>or</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            onClick={() => alert("Sign up with Google")}
            startIcon={<GoogleIcon />}
          >
            Sign up with Google
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            onClick={() => alert("Sign up with Facebook")}
            startIcon={<FacebookIcon />}
          >
            Sign up with Facebook
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
