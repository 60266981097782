import React, { useState } from "react";
import { Search } from "lucide-react";

const CourseCard = ({ title, description, instructor, duration }) => (
  <div className="p-6 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
    <h3 className="mb-2 text-xl font-semibold text-gray-800">{title}</h3>
    <p className="mb-4 text-gray-600">{description}</p>
    <div className="flex items-center justify-between text-sm text-gray-500">
      <span>Instructor: {instructor}</span>
      <span>{duration}</span>
    </div>
  </div>
);

const OfficialCoursesPage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const courses = [
    {
      id: 1,
      title: "Introduction to Shmangus",
      description: "Learn the basics of Shmangus and its core concepts.",
      instructor: "Dr. Shmang",
      duration: "4 weeks",
    },
    {
      id: 2,
      title: "Advanced Shmangus Techniques",
      description: "Master advanced techniques and become a Shmangus expert.",
      instructor: "Prof. Angus",
      duration: "6 weeks",
    },
    {
      id: 3,
      title: "Shmangus for Business",
      description:
        "Apply Shmangus principles to real-world business scenarios.",
      instructor: "Sarah Shmanguson",
      duration: "5 weeks",
    },
    {
      id: 4,
      title: "Creative Shmangus",
      description: "Explore the creative side of Shmangus in this fun course.",
      instructor: "Art Shmangustern",
      duration: "3 weeks",
    },
  ];

  const filteredCourses = courses.filter(
    (course) =>
      course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen px-4 py-12 bg-gradient-to-br from-blue-500 to-purple-600 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <h1 className="mb-4 text-4xl font-bold text-white animate-fade-in-down">
            Explore Our Courses
          </h1>
          <p className="mb-8 text-xl text-white animate-fade-in-up">
            Discover the perfect Shmangus course to elevate your skills
          </p>
        </div>

        <div className="max-w-md mx-auto mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search courses..."
              className="w-full px-4 py-2 text-gray-800 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search
              className="absolute right-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredCourses.map((course) => (
            <CourseCard key={course.id} {...course} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfficialCoursesPage;
