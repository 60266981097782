import React from "react";
import { Navigate } from "react-router-dom";
import { useIsLogin } from "../../modules/AuthenticationProvider";

const Protected = ({ children }) => {
  const isLogin = useIsLogin();
  return isLogin ? <>{children}</> : <Navigate to="/login" />;
};

export default Protected;
