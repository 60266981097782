import Overview from "./View/components/Overview";
import Lessons from "./View/components/Lessons";
import Units from "./View/components/Units";

export const COURSE_VIEW_ITEMS = {
  overview: { label: "Overview", Component: Overview },
  lessons: { label: "Lessons", Component: Lessons },
  units: { label: "Units", Component: Units },
  quizzes: { label: "Quizzes" },
  content: { label: "Content" },
  progress: { label: "Progress" },
  preview: { label: "Preview" },
};

export const CATEGORY_ITEMS = [
  { value: "programming", label: "Programming" },
  { value: "design", label: "Design" },
  { value: "business", label: "Business" },
];

export const DIFFICULTY_ITEMS = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" },
];
