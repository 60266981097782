export const NAVBAR_HEADERS = [
  {
    label: "Dashboard",
    always: false,
    login: true,
    items: [
      {
        label: "Course Learning Dashboard",
        link: "/dashboard/learning",
        always: false,
        login: true,
      },
      {
        label: "Course Management Dashboard",
        link: "/dashboard/manage",
        always: false,
        login: true,
      },
    ],
  },
  { label: "About", link: "/about", always: true },
  {
    label: "Courses",
    always: true,
    items: [
      { label: "Official Courses", link: "/courses/official", always: true },
      { label: "Community Courses", link: "/courses/community", always: true },
    ],
  },
  { label: "Pricing", link: "/pricing", always: true },
  {
    label: "Account",
    always: false,
    login: true,
    items: [
      { label: "Account Settings", link: "/account/settings", always: true },
      { label: "Sign Out", link: "/signout", always: true },
    ],
  },
  {
    label: "Log In/Sign Up",
    always: false,
    login: false,
    link: "/login",
  },
];
