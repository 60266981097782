import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography, Drawer, MenuList } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import NavbarItem from "./NavbarItem";
import MenubarItem from "./MenubarItem";
import { NAVBAR_HEADERS } from "../constants";

export const Navbar = () => {
  const navigate = useNavigate();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="bg-white shadow-md">
      <div ref={anchorEl} className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Box sx={{ display: "flex", alignItems: "center" }} className="h-16">
          <Typography
            className="text-blue-600 "
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              flexShrink: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            Shmangus
          </Typography>
          <Box
            sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              sx={{ display: { xs: "inline-flex", md: "none" } }}
              onClick={toggleMenu}
            >
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                gap: 1,
              }}
            >
              {NAVBAR_HEADERS.map((item) => (
                <NavbarItem key={item.label} item={item} />
              ))}
            </Box>
          </Box>
        </Box>
      </div>
      <Drawer open={isOpen} onClose={toggleMenu} anchor="top">
        <MenuList>
          {NAVBAR_HEADERS.map((item) => (
            <MenubarItem key={item.label} item={item} toggleMenu={toggleMenu} />
          ))}
        </MenuList>
      </Drawer>
    </nav>
  );
};
