import React from "react";
import { Check, X } from "lucide-react";
import { Button, Box } from "@mui/material";

const PricingCard = ({ title, price, features, isPopular }) => (
  <div
    className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 ${
      isPopular ? "border-4 border-blue-500" : ""
    }`}
  >
    <div className="px-6 py-8 border-b border-gray-200 bg-gray-50 sm:px-10">
      <h3 className="text-2xl font-semibold text-gray-900">{title}</h3>
      <p className="mt-4 text-5xl font-extrabold text-gray-900">{price}</p>
      <p className="mt-5 text-lg text-gray-500">
        {price === "Free" ? "Forever" : "per month"}
      </p>
    </div>
    <div className="px-6 pt-6 pb-8 sm:px-10">
      <ul className="space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <div className="flex-shrink-0">
              {feature.included ? (
                <Check className="w-6 h-6 text-green-500" />
              ) : (
                <X className="w-6 h-6 text-red-500" />
              )}
            </div>
            <p className="ml-3 text-base text-gray-700">{feature.text}</p>
          </li>
        ))}
      </ul>
      <div className="mt-8">
        <Button
          fullWidth
          variant="contained"
          color={isPopular ? "primary" : "secondary"}
        >
          Get Started
        </Button>
      </div>
    </div>
  </div>
);

const PricingPage = () => {
  const plans = [
    {
      title: "Free Plan",
      price: "Free",
      features: [
        { text: "Access to all courses", included: true },
        { text: "Access to all materials", included: true },
        { text: "Community forum access", included: true },
        { text: "Ad-supported experience", included: true },
        { text: "Ad-free experience", included: false },
        { text: "Priority support", included: false },
      ],
      isPopular: false,
    },
    {
      title: "Premium Plan",
      price: "$7.99",
      features: [
        { text: "Access to all courses", included: true },
        { text: "Access to all materials", included: true },
        { text: "Community forum access", included: true },
        { text: "Ad-free experience", included: true },
        { text: "Priority support", included: true },
        { text: "Exclusive webinars", included: true },
      ],
      isPopular: true,
    },
  ];

  return (
    <div className="min-h-screen px-4 py-12 bg-gradient-to-br from-blue-500 to-purple-600 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <h1 className="mb-4 text-5xl font-bold text-white animate-fade-in-down">
            Choose Your Shmangus Plan
          </h1>
          <p className="mb-8 text-xl text-white animate-fade-in-up">
            Select the perfect plan to enhance your Shmangus learning journey
          </p>
        </div>

        <div className="grid max-w-4xl gap-8 mx-auto md:grid-cols-2">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>

        <div className="mt-12 text-center">
          <Box
            className="text-lg text-white"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Need help choosing?
            <Button
              variant="text"
              color="white"
              sx={{
                textTransform: "none",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: 20,
              }}
            >
              Contact our sales team
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
