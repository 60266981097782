import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import { editCourse } from "../../../../../queries/course";
import { useUserInfo } from "../../../../../modules/AuthenticationProvider";
import { useAddNotification } from "../../../../../modules/notification";
import { MESSAGES } from "../../../../../modules/constants";
import * as Validator from "../../../../../modules/validators";

const LessonItem = ({ lesson, handleDelete }) => {
  const [itemOpen, setItemOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setItemOpen(!itemOpen)}>
        <ListItemText primary={lesson.title} />
        {itemOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={itemOpen} tileout="auto">
        <Box sx={{ px: 2, mb: 1 }}>
          <Typography>Description</Typography>
        </Box>
        <Box sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="error" size="small">
            Delete Lesson
          </Button>
        </Box>
        <Divider />
      </Collapse>
    </>
  );
};

const validationSchema = Yup.object().shape({
  title: Validator.titleSchema(),
  description: Validator.descriptionSchema(),
});

const Lessons = ({ course, setCourse }) => {
  const { userInfo } = useUserInfo();
  const addNotification = useAddNotification();
  const [lessons, setLessons] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const disableSave = isEqual(lessons, course.lessons);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const onSubmit = (data) => {
    setLessons([...lessons, { id: Date.now(), ...data }]);
    reset({ title: "", description: "" });
    setOpen(false);
  };

  const handleEdit = () => {
    setLoading(true);
    editCourse(
      course._id,
      { lessons: JSON.stringify(lessons) },
      userInfo.accessToken
    )
      .then((res) => {
        setCourse(res.data.data);
        addNotification({ type: "success", content: "Lessons are updated" });
      })
      .catch((err) => {
        addNotification({ type: "error", content: err });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLessons(course.lessons);
  }, [course]);

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={5} sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Create a Lesson
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={disableSave}
            color="success"
            onClick={handleEdit}
          >
            Save Lessons
          </LoadingButton>
        </Box>
        {!lessons.length && (
          <Typography color="primary" textAlign="center">
            No registered lessons
          </Typography>
        )}
        <List>
          {lessons.map((lesson) => (
            <LessonItem key={lesson.id || lesson._id} lesson={lesson} />
          ))}
        </List>
      </Paper>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Lesson Creation</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="title"
                  label="Lesson Title"
                  variant="standard"
                  size="small"
                  required
                  value={value}
                  onChange={onChange}
                  error={!!errors.title}
                  helperText={MESSAGES[errors.title?.message] || ""}
                  sx={{ maxWidth: 400, width: "100%", mb: 2 }}
                />
              )}
            />
            <Box sx={{ mb: 2 }}>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    name="description"
                    label="Lesson Description"
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    multiline
                    rows={4}
                    required
                    error={!!errors.description}
                    helperText={MESSAGES[errors.description?.message] || ""}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" type="submit">
                Add Lesson Part
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Lessons;
