import React, { useState } from "react";
import { Grid2, Box, Typography, Button, Paper } from "@mui/material";
import CourseCreationDialog from "../../components/CourseCreationDialog";

const Overview = ({ course, setCourse }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid2 container spacing={3}>
        <Grid2 size={4}>
          <Box
            component="img"
            src={course.thumbnail.url}
            alt={`Image for ${course.title}`}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 size={8}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpen(true)}
              >
                Edit
              </Button>
            </Box>
            <Typography variant="h3" sx={{ color: "primary.main", mb: 2 }}>
              {course.title}
            </Typography>
            <Typography sx={{ mb: 2 }}>{course.description}</Typography>
            <Typography>Category: {course.category}</Typography>
            <Typography>Difficulty: {course.difficulty}</Typography>
            <Typography>Estimation: {course.estimation}h</Typography>
            <Typography>{course.public ? "Public" : "Private"}</Typography>
          </Paper>
        </Grid2>
      </Grid2>
      <CourseCreationDialog
        open={open}
        handleClose={handleClose}
        course={course}
        setCourse={setCourse}
      />
    </>
  );
};

export default Overview;
