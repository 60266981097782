import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  User,
  Lock,
  Bell,
  CreditCard,
  Globe,
} from "lucide-react";

const Section = ({ title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-6 overflow-hidden border rounded-lg border-white/20">
      <button
        className="flex items-center justify-between w-full px-4 py-3 text-white bg-white/10"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          {React.cloneElement(icon, { className: "text-white" })}
          <h2 className="ml-2 text-lg font-semibold">{title}</h2>
        </div>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && <div className="p-4 bg-white/5">{children}</div>}
    </div>
  );
};

const AccountSettingsPage = () => {
  return (
    <div className="min-h-screen px-4 py-12 bg-gradient-to-br from-blue-500 to-purple-600 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="mb-8 text-4xl font-bold text-center text-white">
          Account Settings
        </h1>

        <Section title="Profile Information" icon={<User size={20} />}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-white"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <div>
              <label
                htmlFor="full-name"
                className="block text-sm font-medium text-white"
              >
                Full Name
              </label>
              <input
                type="text"
                id="full-name"
                name="full-name"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <button className="w-full px-4 py-2 font-bold text-blue-600 transition duration-300 ease-in-out bg-white rounded-full hover:bg-blue-100">
              Save Changes
            </button>
          </div>
        </Section>

        <Section title="Security" icon={<Lock size={20} />}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="current-password"
                className="block text-sm font-medium text-white"
              >
                Current Password
              </label>
              <input
                type="password"
                id="current-password"
                name="current-password"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <div>
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-white"
              >
                New Password
              </label>
              <input
                type="password"
                id="new-password"
                name="new-password"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-white"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                className="block w-full mt-1 text-white border rounded-md shadow-sm border-white/20 bg-white/10"
              />
            </div>
            <button className="w-full px-4 py-2 font-bold text-blue-600 transition duration-300 ease-in-out bg-white rounded-full hover:bg-blue-100">
              Update Password
            </button>
          </div>
        </Section>

        <Section title="Notifications" icon={<Bell size={20} />}>
          <div className="space-y-4">
            {[
              "Email notifications",
              "Push notifications",
              "SMS notifications",
            ].map((item) => (
              <div key={item} className="flex items-center">
                <input
                  type="checkbox"
                  id={item.replace(" ", "-")}
                  name={item.replace(" ", "-")}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500 border-white/20"
                />
                <label
                  htmlFor={item.replace(" ", "-")}
                  className="block ml-2 text-sm text-white"
                >
                  {item}
                </label>
              </div>
            ))}
            <button className="w-full px-4 py-2 font-bold text-blue-600 transition duration-300 ease-in-out bg-white rounded-full hover:bg-blue-100">
              Save Preferences
            </button>
          </div>
        </Section>

        <Section title="Billing" icon={<CreditCard size={20} />}>
          <div className="space-y-4">
            <p className="text-sm text-white">
              Your current plan: <strong>Premium</strong>
            </p>
            <button className="w-full px-4 py-2 font-bold text-blue-600 transition duration-300 ease-in-out bg-white rounded-full hover:bg-blue-100">
              Upgrade Plan
            </button>
            <button className="w-full px-4 py-2 font-bold text-white transition duration-300 ease-in-out border rounded-full border-white/20 hover:bg-white/10">
              View Billing History
            </button>
          </div>
        </Section>

        <Section title="Language & Region" icon={<Globe size={20} />}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="language"
                className="block text-sm font-medium text-white"
              >
                Language
              </label>
              <select
                id="language"
                name="language"
                className="block w-full py-2 pl-3 pr-10 mt-1 text-white border rounded-full bg-white/10 border-white/20 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option>English</option>
                <option>Spanish</option>
                <option>French</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="region"
                className="block text-sm font-medium text-white"
              >
                Region
              </label>
              <select
                id="region"
                name="region"
                className="block w-full py-2 pl-3 pr-10 mt-1 text-white border rounded-full bg-white/10 border-white/20 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>United Kingdom</option>
              </select>
            </div>
            <button className="w-full px-4 py-2 font-bold text-blue-600 transition duration-300 ease-in-out bg-white rounded-full hover:bg-blue-100">
              Save Preferences
            </button>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default AccountSettingsPage;
