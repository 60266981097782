import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useUserInfo } from "../../../../modules/AuthenticationProvider";
import { COURSE_VIEW_ITEMS } from "../constants";
import { getCourse } from "../../../../queries/course";
import { MESSAGES } from "../../../../modules/constants";

const CourseManagementView = () => {
  const { courseId } = useParams();
  const { userInfo } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState("overview");

  const Element = COURSE_VIEW_ITEMS[selected].Component;

  const handleTabChange = (_, newValue) => {
    setSelected(newValue);
  };

  useEffect(() => {
    setLoading(true);
    getCourse(courseId, userInfo.accessToken)
      .then((res) => {
        setCourse(res.data.data);
        setError(null);
      })
      .catch((err) => {
        const errorCode = err.response?.data.error;
        setError(MESSAGES[errorCode] || errorCode || err.mesage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [courseId, userInfo.accessToken]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        sx={{
          textAlign: "center",
          fontSize: 24,
          p: 4,
          fontWeight: "bold",
          color: "error.main",
        }}
      >
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ maxWidth: 1200, width: "100%", mx: "auto" }}>
        <Tabs value={selected} variant="fullWidth" onChange={handleTabChange}>
          {Object.keys(COURSE_VIEW_ITEMS).map((key) => (
            <Tab key={key} value={key} label={COURSE_VIEW_ITEMS[key].label} />
          ))}
        </Tabs>
        <Box sx={{ p: 2 }}>
          <Element course={course} setCourse={setCourse} />
        </Box>
      </Box>
    </Box>
  );
};

export default CourseManagementView;
