import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import CourseItem from "./components/CourseItem";
import CourseCreationDialog from "./components/CourseCreationDialog";
import { useUserInfo } from "../../../modules/AuthenticationProvider";
import { getCourses } from "../../../queries/course";
import { MESSAGES } from "../../../modules/constants";

const CourseManagementDashboard = () => {
  const { userInfo } = useUserInfo();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creationOpen, setCreationOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setCreationOpen(false);
  };

  const fetchCourse = () => {
    setLoading(true);
    getCourses(userInfo.accessToken)
      .then((res) => {
        setCourses(res.data.courses);
      })
      .catch((err) => {
        const errorCode = err.response?.data.error;
        setError(MESSAGES[errorCode] || errorCode || err.mesage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  return (
    <Box sx={{ px: 3, py: 6 }}>
      <Box sx={{ maxWidth: 1200, width: "100%", mx: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Typography
            variant="h2"
            sx={{
              color: "primary.main",
              fontSize: 28,
              flexGrow: 1,
              fontWeight: "bold",
            }}
          >
            Course Management Dashboard
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            disabled={loading || !!error}
            onClick={() => setCreationOpen(true)}
          >
            Create New Course
          </Button>
        </Box>
        <Box>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <CircularProgress size={40} />
            </Box>
          )}
          {!loading && !!error && (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 24,
                p: 2,
                fontWeight: "bold",
                color: "error.main",
              }}
            >
              {error}
            </Typography>
          )}
          {!loading && !error && (
            <>
              {!courses.length && (
                <Typography
                  color="primary"
                  sx={{ p: 2, textAlign: "center", fontSize: "20" }}
                >
                  No courses created
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {courses.map((course) => (
                  <CourseItem key={course.id} course={course} />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <CourseCreationDialog open={creationOpen} handleClose={handleClose} />
    </Box>
  );
};

export default CourseManagementDashboard;
