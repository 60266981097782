import { getApiClient } from "../modules/axios";

export const signUp = (data) => {
  return getApiClient({ "content-type": "application/json" }).post(
    "/auth/signup",
    data
  );
};

export const signIn = (data) => {
  return getApiClient({ "content-type": "application/json" }).post(
    "/auth/login",
    data
  );
};

export const refresh = () => {
  return getApiClient({ "content-type": "application/json" }).post(
    "/auth/refresh"
  );
};

export const signout = () => {
  return getApiClient({ "content-type": "application/json" }).post(
    "/auth/signout"
  );
};
