import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AuthenticationProvider from "./modules/AuthenticationProvider";
import Mainlayout from "./components/Layout";
import Protected from "./components/Protected";
import SignUp from "./pages/auth/register/SignUp";
import SignIn from "./pages/auth/login/SignIn";
import Home from "./pages/Home";
import AboutPage from "./pages/About";
import PricingPage from "./pages/PricingPage";
import OfficialCoursesPage from "./pages/Courses/Official";
import CommunityCoursesPage from "./pages/Courses/Community";
import AccountSettingsPage from "./pages/Account/Settings";
import CourseManagementDashboard from "./pages/Dashboard/Management/";
import CourseManagementView from "./pages/Dashboard/Management/View";

function App() {
  return (
    <AuthenticationProvider>
      <RecoilRoot>
        <BrowserRouter>
          <Mainlayout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route
                path="/courses/official"
                element={<OfficialCoursesPage />}
              />
              <Route
                path="/courses/community"
                element={<CommunityCoursesPage />}
              />
              <Route
                path="/account/settings"
                element={<AccountSettingsPage />}
              />
              <Route
                path="/dashboard/manage"
                element={
                  <Protected>
                    <CourseManagementDashboard />
                  </Protected>
                }
              />
              <Route
                path="/dashboard/manage/:courseId"
                element={
                  <Protected>
                    <CourseManagementView />
                  </Protected>
                }
              />
            </Routes>
          </Mainlayout>
        </BrowserRouter>
      </RecoilRoot>
    </AuthenticationProvider>
  );
}

export default App;
