import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Popover } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useUserInfo } from "../../../modules/AuthenticationProvider";
import { signout } from "../../../queries/auth";

const NavbarItem = ({ item, isSubItem }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userInfo, setUserInfo } = useUserInfo();

  const isVisible = item.always || item.login === !!userInfo;

  const handleClick = (e) => {
    if (item.items) {
      setAnchorEl(e.currentTarget);
    } else {
      if (item.link.includes("signout")) {
        signout().then(() => {
          setAnchorEl(null);
          setUserInfo(null);
        });
      } else {
        navigate(item.link);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        sx={{
          textTransform: "unset",
          justifyContent: isSubItem ? "flex-start" : "center",
        }}
        endIcon={
          item.items ? (
            anchorEl ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )
          ) : (
            <></>
          )
        }
        onClick={handleClick}
      >
        {item.label}
      </Button>
      {!!item.items && (
        <Popover
          id={`navitem-${item.label}`}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {item.items.map((subItem) => (
              <NavbarItem key={subItem.label} item={subItem} isSubItem />
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default NavbarItem;
