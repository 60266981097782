import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  FormLabel,
  FormControl,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ForgotPassword from "./ForgotPassword";
import { GoogleIcon, FacebookIcon } from "./CustomIcons";
import { signIn } from "../../../queries/auth";
import {
  useUserInfo,
  useIsLogin,
} from "../../../modules/AuthenticationProvider";
import { useAddNotification } from "../../../modules/notification";
import * as Validator from "../../../modules/validators";
import { MESSAGES } from "../../../modules/constants";

const validationSchema = Yup.object().shape({
  email: Validator.emailSchema(),
  password: Validator.passwordSchema(),
});

export default function SignIn() {
  const addNotification = useAddNotification();
  const [loading, setLoading] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const navigate = useNavigate();
  const { setUserInfo } = useUserInfo();
  const isLogin = useIsLogin();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    signIn(data)
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((err) => {
        addNotification({
          type: "error",
          content: err,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/dashboard/learning");
    }
  }, [isLogin, navigate]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: 12, pb: 12 }}>
      <Paper elevation={5} sx={{ maxWidth: 450, width: "100%", padding: 4 }}>
        <Typography
          component="h1"
          variant="h4"
          textAlign="center"
          sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
        >
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoComplete="email"
                  name="email"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  placeholder="Type email ..."
                  error={!!errors.email}
                  helperText={MESSAGES[errors.email?.message] || ""}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormLabel htmlFor="password">Password</FormLabel>
              <Button
                variant="text"
                onClick={() => setForgotOpen(true)}
                sx={{ alignSelf: "baseline", textTransform: "none" }}
              >
                Forgot your password?
              </Button>
            </Box>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="password"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.password}
                  helperText={MESSAGES[errors.password?.message] || ""}
                />
              )}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <ForgotPassword
            open={forgotOpen}
            handleClose={() => setForgotOpen(false)}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
          >
            Sign in
          </LoadingButton>
          <Typography sx={{ textAlign: "center" }}>
            {"Don't have an account?"}
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() => navigate("/register")}
            >
              Sign Up
            </Button>
          </Typography>
        </Box>
        <Divider>or</Divider>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            onClick={() => alert("Sign in with Google")}
            startIcon={<GoogleIcon />}
          >
            Sign in with Google
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            onClick={() => alert("Sign in with Facebook")}
            startIcon={<FacebookIcon />}
          >
            Sign in with Facebook
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
