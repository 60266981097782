import React from "react";
import { BookOpen, Users, Award } from "lucide-react";

export const ABOUT_FEATURES = [
  {
    icon: BookOpen,
    title: "Extensive Course Library",
    description:
      "Access a wide range of courses covering various aspects of Shmangus, from beginner to advanced levels.",
  },
  {
    icon: Users,
    title: "Vibrant Community",
    description:
      "Join a thriving community of Shmangus enthusiasts, share knowledge, and collaborate on projects.",
  },
  {
    icon: Award,
    title: "Expert Instructors",
    description:
      "Learn from industry-leading Shmangus experts who bring real-world experience to their teaching.",
  },
];
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="p-6 bg-white rounded-lg shadow-md">
    <Icon className="w-12 h-12 mb-4 text-blue-500" />
    <h3 className="mb-2 text-xl font-semibold text-gray-900">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AboutPage = () => {
  return (
    <div className="min-h-screen px-4 py-12 bg-gradient-to-br from-blue-500 to-purple-600 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <h1 className="mb-4 text-5xl font-bold text-white animate-fade-in-down">
            About Shmangus Learn
          </h1>
          <p className="mb-8 text-xl text-white animate-fade-in-up">
            Empowering the next generation of Shmangus experts
          </p>
        </div>

        <div className="p-8 mb-12 bg-white rounded-lg shadow-xl">
          <h2 className="mb-4 text-3xl font-semibold text-gray-900">
            Our Mission
          </h2>
          <p className="text-lg text-gray-600">
            At Shmangus Learn, we're passionate about making Shmangus education
            accessible, engaging, and effective. Our platform is designed to
            help learners of all levels master the art and science of Shmangus,
            fostering a global community of innovators and problem-solvers.
          </p>
        </div>

        <div className="grid gap-8 mb-12 md:grid-cols-3">
          {ABOUT_FEATURES.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        <div className="text-center">
          <h2 className="mb-4 text-3xl font-semibold text-white">
            Ready to start your Shmangus journey?
          </h2>
          <a
            href="/signup"
            className="inline-block px-6 py-3 font-bold text-blue-600 transition duration-300 bg-white rounded-full hover:bg-blue-100"
          >
            Get Started Today
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
