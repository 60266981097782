import React from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid2,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import {
  DriveFolderUpload as DriveFolderUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { CATEGORY_ITEMS, DIFFICULTY_ITEMS } from "../constants";
import { MESSAGES } from "../../../../modules/constants";

const CourseCreation = ({ control, errors, setValue, watch, course }) => {
  const thumbnail = watch("thumbnail");
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/png": [] },
    maxFiles: 1,
    onDrop: (files) => {
      if (files.length) {
        setValue("thumbnail", files[0], { shouldValidate: true });
      }
    },
  });

  const handleFileDelete = (e) => {
    e.stopPropagation();
    setValue("thumbnail", null, { shouldValidate: true });
  };

  return (
    <Box>
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            name="title"
            label="Course Title"
            variant="standard"
            size="small"
            required
            value={value}
            onChange={onChange}
            error={!!errors.title}
            helperText={MESSAGES[errors.title?.message] || ""}
            sx={{ maxWidth: 400, width: "100%", mb: 2 }}
          />
        )}
      />
      <Box sx={{ mb: 2 }}>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              name="description"
              label="Course Description"
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              multiline
              rows={4}
              required
              error={!!errors.description}
              helperText={MESSAGES[errors.description?.message] || ""}
            />
          )}
        />
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel htmlFor="thumbnail" required>
          Course Thumbnail
        </FormLabel>
        <Box
          {...getRootProps()}
          sx={{
            borderWidth: 5,
            borderStyle: "dashed",
            borderColor: !!errors.thumbnail ? "#d32f2f" : "lightGray",
            borderRadius: 4,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input {...getInputProps()} />
          {!!course && !thumbnail ? (
            <Box
              component="img"
              src={course.thumbnail.url}
              sx={{ height: 80 }}
            />
          ) : (
            <DriveFolderUploadIcon sx={{ mb: 1 }} />
          )}
          {thumbnail ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>Selected file: {thumbnail.name}</Typography>
              <IconButton size="small" onClick={handleFileDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : (
            <Typography>
              {course
                ? "To change this thumbnail, drag or click here"
                : "Drag a Thumbnail file here, or click to select a file"}
            </Typography>
          )}
          {!!errors.thumbnail && (
            <Typography sx={{ mt: 2, color: "#d32f2f" }}>
              {MESSAGES[errors.thumbnail?.message] || ""}
            </Typography>
          )}
        </Box>
      </FormControl>
      <Grid2 container spacing={2}>
        <Grid2 size={6}>
          <FormControl fullWidth>
            <InputLabel id="course-category">Category</InputLabel>
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="course-category"
                  name="category"
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!errors.category}
                  label="Category"
                  helperText={MESSAGES[errors.category?.message] || ""}
                >
                  {CATEGORY_ITEMS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl fullWidth>
            <InputLabel id="course-difficulty">Difficulty</InputLabel>
            <Controller
              name="difficulty"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="course-difficulty"
                  name="difficulty"
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!errors.difficulty}
                  label="Difficulty"
                  helperText={MESSAGES[errors.difficulty?.message] || ""}
                >
                  {DIFFICULTY_ITEMS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <Controller
            name="estimation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                type="number"
                name="estimation"
                label="Estimated Duration (hours)"
                fullWidth
                size="small"
                value={value}
                onChange={onChange}
                required
                error={!!errors.estimation}
                helperText={MESSAGES[errors.estimation?.message] || ""}
              />
            )}
          />
        </Grid2>
        <Grid2 size={6}>
          <Controller
            name="public"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label="Course is public:"
                labelPlacement="start"
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CourseCreation;
