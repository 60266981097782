import { getApiClient } from "../modules/axios";

export const createCourse = (data, accessToken) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("thumbnail", data.thumbnail);
  formData.append("category", data.category);
  formData.append("difficulty", data.difficulty);
  formData.append("estimation", data.estimation);
  formData.append("public", data.public);

  return getApiClient({
    "content-type": "multipart/form-data",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }).post("/course/", formData);
};

export const getCourse = (courseId, accessToken) => {
  return getApiClient({
    "content-type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }).get(`/course/${courseId}`);
};

export const getCourses = (accessToken) => {
  return getApiClient({
    "content-type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }).get("/course/");
};

export const editCourse = (courseId, data, accessToken) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      return;
    }
    formData.append(key, data[key]);
  });

  return getApiClient({
    "content-type": "multipart/form-data",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }).put(`/course/${courseId}`, formData);
};

export const removeCourse = (courseId, accessToken) => {
  return getApiClient({
    "content-type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  }).delete(`/course/${courseId}`);
};
