export const MESSAGES = {
  invalid_email_shape: "Email does not have correct format",
  invalid_email_length: "Maximum email length should be 150",
  required: "This field is required",
  invalid_password_length: "Password length should be 8 to 100",
  invalid_password_character:
    "Password includes lowercase, uppercase characters, digits, and special characters",
  invalid_name_length: "Name should have less than 50 characters",
  invalid_title_length: "Title should have less than 150 characters",
  invalid_description_length:
    "Description should have less than 1000 characters",
  invalid_file_size: "File size should be less than 5MB",
  invalid_file_format: "File format should be .png",
  invalid_estimation: "Estimation time should be positive",
  invalid_lesson_length: "At least one lesson part is required",
  invalid_unit_length: "At least one unit is required",
  invalid_inputs: "Input values are incorrect.",
  internal_server_error: "Internal Server Error occured! Try it later!",
  success_signup: "Account created successfully",
  email_or_password_is_incorrect: "Email or password is incorrect",
  unauthorized: "You are not authorized to access",
  not_found: "Cannot find the required info",
  duplicated_email: "Email is already existed",
  user_not_found: "Cannot find the user",
  success_delete: "Successfully deleted!",
};
