import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Tooltip,
  Chip,
} from "@mui/material";
import { Lock as LockIcon, Public as PublicIcon } from "@mui/icons-material";

const CourseItem = ({ course }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 360, width: "100%" }}>
      <CardActionArea
        onClick={() => navigate(`/dashboard/manage/${course._id}`)}
      >
        <CardMedia
          component="img"
          image={course.thumbnail.url}
          alt={`Image for ${course.title}`}
          sx={{ height: 140 }}
        />
        <CardContent>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {course.title}
            </Typography>
            <Tooltip
              title={course.public ? "Public" : "Private"}
              placement="top"
            >
              {course.public ? <PublicIcon /> : <LockIcon />}
            </Tooltip>
          </Box>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
            {course.description}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {!course.published && (
              <Chip size="small" color="warning" label="Draft" />
            )}
            <Chip size="small" color="primary" label={course.category} />
            <Chip size="small" color="error" label={course.difficulty} />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CourseItem;
