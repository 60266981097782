import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { useIsLogin } from "../../modules/AuthenticationProvider";

const Home = () => {
  const navigate = useNavigate();
  const isLogin = useIsLogin();

  const handleStarted = () => {
    navigate(isLogin ? "/dashboard/learning" : "/login");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#4188fa",
      }}
    >
      <Typography
        textAlign="center"
        variant="h1"
        color="white"
        sx={{ fontSize: 80, mb: 1 }}
      >
        Shmangus Learn
      </Typography>
      <Typography textAlign="center" color="white" sx={{ fontSize: 24, mb: 5 }}>
        A new way of learning
      </Typography>
      <Button
        variant="contained"
        size="large"
        color="success"
        startIcon={<ArrowForwardIcon />}
        sx={{ textTransform: "none", borderRadius: 10, fontWeight: "bold" }}
        onClick={handleStarted}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default Home;
