import React, { useState, useEffect, createContext, useContext } from "react";
import { Box, CircularProgress } from "@mui/material";
import { refresh } from "../queries/auth";

const AuthenticationContext = createContext(null);

const AuthenticationProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    refresh()
      .then((res) => setUserInfo(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <AuthenticationContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;

export const useUserInfo = () => {
  const value = useContext(AuthenticationContext);
  return value;
};

export const useIsLogin = () => {
  const value = useContext(AuthenticationContext);
  return !!value.userInfo;
};
