import React, { useState, useEffect } from "react";
import { Search } from "lucide-react";

const CourseCard = ({ title, description, instructor, image, tags }) => (
  <div className="overflow-hidden transition duration-300 transform bg-white rounded-lg shadow-md hover:shadow-xl hover:-translate-y-1">
    <img src={image} alt={title} className="object-cover w-full h-48" />
    <div className="p-6">
      <h3 className="mb-2 text-xl font-semibold text-gray-800">{title}</h3>
      <p className="mb-4 text-gray-600">{description}</p>
      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded"
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="flex items-center justify-between text-sm text-gray-500">
        <span>By {instructor}</span>
        <button className="px-4 py-2 font-bold text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600">
          Learn More
        </button>
      </div>
    </div>
  </div>
);

const CommunityCoursesPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Simulating an API call to fetch courses
    const fetchCourses = async () => {
      // In a real application, you would fetch data from an API here
      const mockCourses = [
        {
          id: 1,
          title: "DIY Shmangus Projects",
          description: "Learn to create amazing Shmangus projects at home.",
          instructor: "Crafty Shmanguser",
          image: "/api/placeholder/400/300",
          tags: ["DIY", "Creative", "Beginner"],
        },
        {
          id: 2,
          title: "Shmangus for Game Development",
          description: "Apply Shmangus principles to create engaging games.",
          instructor: "Gamer Shmang",
          image: "/api/placeholder/400/300",
          tags: ["Gaming", "Advanced", "Coding"],
        },
        {
          id: 3,
          title: "Eco-Friendly Shmangus",
          description:
            "Discover sustainable Shmangus practices for a greener future.",
          instructor: "Green Shmangus",
          image: "/api/placeholder/400/300",
          tags: ["Sustainability", "Intermediate", "Eco"],
        },
        {
          id: 4,
          title: "Shmangus in Literature",
          description:
            "Explore the intersection of Shmangus and classic literature.",
          instructor: "Bookworm Shmanguist",
          image: "/api/placeholder/400/300",
          tags: ["Literature", "Analysis", "Culture"],
        },
        {
          id: 5,
          title: "Shmangus Cooking Techniques",
          description:
            "Learn to incorporate Shmangus principles in your culinary creations.",
          instructor: "Chef Shmanguson",
          image: "/api/placeholder/400/300",
          tags: ["Cooking", "Practical", "Lifestyle"],
        },
        {
          id: 6,
          title: "Shmangus for Kids",
          description: "A fun introduction to Shmangus for young learners.",
          instructor: "Kid-Friendly Shmanguser",
          image: "/api/placeholder/400/300",
          tags: ["Kids", "Education", "Fun"],
        },
      ];
      setCourses(mockCourses);
    };

    fetchCourses();
  }, []);

  const filteredCourses = courses.filter(
    (course) =>
      course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <div className="min-h-screen px-4 py-12 bg-gradient-to-br from-blue-500 to-purple-600 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <h1 className="mb-4 text-5xl font-bold text-white animate-fade-in-down">
            Community Courses
          </h1>
          <p className="mb-8 text-xl text-white animate-fade-in-up">
            Explore unique Shmangus courses created by our vibrant community
          </p>
        </div>

        <div className="max-w-md mx-auto mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search courses or tags..."
              className="w-full px-4 py-2 text-gray-800 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search
              className="absolute right-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredCourses.map((course) => (
            <CourseCard key={course.id} {...course} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityCoursesPage;
