import React from "react";
import { useRecoilState } from "recoil";
import { Snackbar, Alert, Box } from "@mui/material";
import { Navbar } from "./components/Navbar";
import { notificationsState } from "../../states/notification";

const MainLayout = ({ children }) => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const handleClose = () => {
    setNotifications(notifications.slice(1));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#eee",
      }}
    >
      <Navbar />
      <main className="flex-grow">{children}</main>
      {!!notifications.length && (
        <Snackbar
          open={notifications.length}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={notifications[0].type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {notifications[0].message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default MainLayout;
