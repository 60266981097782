import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, ListItemText, MenuList } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useUserInfo } from "../../../modules/AuthenticationProvider";
import { signout } from "../../../queries/auth";

const MenubarItem = ({ item, toggleMenu }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();

  const isVisible = item.always || item.login === !!userInfo;

  const handleClick = () => {
    if (item.items) {
      setOpen(!open);
    } else {
      if (item.link.includes("signout")) {
        signout().then(() => setUserInfo(null));
      } else {
        navigate(item.link);
        toggleMenu();
      }
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemText>{item.label}</ListItemText>
        {!!item.items && <>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
      </MenuItem>
      {open && (
        <MenuList sx={{ px: 2 }}>
          {item.items.map((subItem) => (
            <MenubarItem item={subItem} toggleMenu={toggleMenu} />
          ))}
        </MenuList>
      )}
    </>
  );
};

export default MenubarItem;
